import "../css/Home.css";
import logo from '../images/AbbeyLogoBW.png';
// import title from '../images/Title.png';

import { Link } from 'react-router-dom';

export default function Home() {
    return (
        <div className="LandingPage">
            <div className="Content">
                <header>

                    <Link to='/Team' className="link">
                        <div class="Header-Tab">                       
                            Abbey Team
                        </div>
                    </Link>

                    {/* <Link to='/Services' className="link">
                        <div class="Header-Tab">                       
                            Services
                        </div>
                    </Link> */}

                    {/* <Link to='/Quotes' className="link">
                        <div className="Header-Tab">
                            Quotes
                        </div>
                    </Link> */}

                    <Link to='/About' className="link">
                        <div className="Header-Tab">
                            About Us
                        </div>
                    </Link>

                    <Link to='/Contact' className="link">
                        <div className="Header-Tab">
                            Contact
                        </div>
                    </Link>
                    
                    <Link to='https://abbeyescrow.sharefile.com/r-r7553f8784e9548089ae39478d3bda709' className="link">
                        <div className="Header-Tab">
                            Secure Upload
                        </div>
                    </Link>
                </header>
                <div className="Logo-Container-lp">
                    <img src={logo} className="Cascadia-logo-lp" alt="logo" />
                </div>

                <div className="Title-Container fadeIn">
                    
                    <div className="Title-Text-Container">
                        {/* <h1>Premier Landscapes</h1> */}
                        <h1>Abbey Escrow Services</h1>
                    </div>
                    
                    <div className="SubTitle-Container">
                        <div className="Info">
                            <h2>Granite Bay, CA<br/>
                                <div style={{color:'rgba(255,255,255,0.6)'}}>License #96DBO-195088</div>  
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="Get-Quote-Button-Container fadeIn">
                    <Link to='/Contact' className="link">           
                        <button>Contact Us</button> 
                    </Link>
                </div> 
            </div>
        </div>
    );
}